import React, { useState, useMemo, useEffect } from "react";
import api from "../services/api";
import chainsConfig from "../config/chains.json";

// Build a lookup map: chainId => blockExplorerUrl
const blockExplorerUrls: Record<number, string> = chainsConfig.reduce(
  (acc: Record<number, string>, chain: any) => {
    acc[chain.chainId] = chain.blockExplorerUrl;
    return acc;
  },
  {}
);

// Mapping of parentNftId to portfolio name
const portfolioNames: Record<number, string> = {
  680: "Liquidity Usd",
  807: "Liquidity Brl",
  678: "Bitcoin",
  679: "Ethereum",
  804: "Liquidity Matic",
  862: "Liquidity Eur",
  940: "Liquidity Ether",
  932: "Matic",
  941: "Aave",
  942: "Uniswap",
  943: "Lido",
  982: "Paxg",
  998: "Liquidity Usd Usdt",
  1323: "Cassio Alt Power Basket",
  1327: "Cassio Feel The Burn Basket",
  1326: "Cassio Just Stables Basket",
  1325: "Cassio Classic Hodler Basket",
  1220: "Grt",
  1221: "Chainlink",
  1884: "Avax",
  1885: "Shib",
  1886: "Ocean",
  1887: "Apecoin",
  1881: "Liquidity Matic Usdc",
  1882: "Liquidity Matic Eth",
  1883: "Liquidity Eth Usdc",
  1890: "Liquidity Wbtc Usdc",
  2044: "Render Network",
  2045: "Decentraland",
  2046: "Curve DAO Token",
  2047: "Mask Network",
  2048: "The Sandbox",
  2433: "Hazim Regen Basket",
  3012: "Solana",
  3013: "Synthetix",
  3014: "Gains",
};

// Each portfolio gets a unique emoji
const portfolioEmojis: Record<number, string> = {
  680: "🔁",
  807: "🦜",
  678: "💎",
  679: "🔷",
  804: "🔶",
  862: "💶",
  940: "💧",
  932: "🔺",
  941: "🏦",
  942: "🦄",
  943: "🧩",
  982: "🏅",
  998: "💹",
  1323: "⚡",
  1327: "🌋",
  1326: "🔒",
  1325: "🗿",
  1220: "🔤",
  1221: "🔗",
  1884: "🏔️",
  1885: "🐕",
  1886: "🌊",
  1887: "🦍",
  1881: "🔵",
  1882: "🌀",
  1883: "🧿",
  1890: "🪐",
  2044: "🖼️",
  2045: "🌐",
  2046: "➰",
  2047: "🎭",
  2048: "🧱",
  2433: "🌿",
  3012: "🌞",
  3013: "⚙️",
  3014: "📈",
};

interface Signer {
  address: string;
  type: string;
}

interface Token {
  assetId: string;
  chainId: number;
  symbol: string;
  address: string;
  decimals?: number;
  name?: string;
  coinKey?: string;
  logoURI?: string;
  priceUSD?: string;
}

interface SendingReceivingInfo {
  chainId: number;
  txHash: string;
  txLink?: string;
  amount?: string;
  token?: Token;
  gasPrice?: string;
  gasUsed?: string;
  gasToken?: Token;
  gasAmount?: string;
  gasAmountUSD?: string;
  amountUSD?: string;
  value?: string;
  timestamp?: number;
}

interface RawStatus {
  transactionId?: string;
  sending?: SendingReceivingInfo;
  receiving?: SendingReceivingInfo;
  lifiExplorerLink?: string;
  fromAddress?: string;
  toAddress?: string;
  tool?: string;
  status?: string;
  substatus?: string;
  substatusMessage?: string;
  metadata?: {
    integrator?: string;
  };
  bridgeExplorerLink?: string;
}

interface Swap {
  provider: string;
  price?: string;
  fromWalletAddress?: string;
  fromToken?: Token;
  toToken?: Token;
  fromAmount?: string;
  toAmount?: string;
  toMinAmount?: string;
  swapError?: string;
  swapStatus?: string;
  userOpHash?: string;
  sending?: {
    chainId: number;
    txHash: string;
  };
  receiving?: {
    chainId: number;
    txHash: string;
  };
  rawStatus?: RawStatus;
}

interface RampOrder {
  fiat: string;
  fiatAmount: number;
  assetAmount: number;
  createdAt: string;
  updatedAt: string;
  status: string;
  mintStatus?: string;
}

interface BasketAllocation {
  assetId: string;
  amount: string;
  fraction: number;
  price: number;
  address: string;
  color?: string;
  nestedTypes?: string[];
  name: string;
  type: string;
  symbol: string;
  logos: {
    logoUri: string;
    name: string;
    symbol: string;
    color?: string;
  }[];
  retired?: any;
  rewards: any[];
  linkedAssets?: {
    assetId: string;
    fraction: number;
  }[];
  apy: number;
  yield: any[];
  category?: string[];
}

interface Basket {
  nftId: number;
  parentNftId?: number;
  allocation: BasketAllocation[];
  apy: number;
  createdOn: string;
  name: string;
  value: number;
  walletAddress: string;
  pnl: number;
  grossPnl: number;
}

interface DeFiBasketTransaction {
  functionName: string;
  transactionHash: string;
  parentNftId?: number;
  executedOn: string;
  inputs: any[];
  outputs: any[];
}

interface SmartAccount {
  address: string;
  chainId: number;
  value?: number;
  nonce?: number;
  createdAt?: Date;
  updatedAt?: Date;
  lastAccessed?: Date;
  cachedAssetBalances?: any[];
  signers: Signer[];
  swaps?: Swap[];
  rampOrders?: RampOrder[];
  transactions?: DeFiBasketTransaction[];
  baskets?: Basket[];
}

interface AddressRecord {
  address: string;
  type: number;
}

interface User {
  id?: string;                // <-- ADDED: Made optional
  email?: string;             // <-- ADDED: Made optional
  kycLevel?: boolean;         // <-- ADDED: Made optional
  reasonForKycFailure?: string;
  smartAccounts?: SmartAccount[];  // <-- ADDED: Made optional
  addresses?: AddressRecord[];     // <-- ADDED: Made optional
  taxId?: string;
  kycType?: string;
  moneriumId?: string;
  moneriumStatus?: string;
  birthDate?: Date;
}

interface GroupedAccountData {
  address: string;
  totalValue: number;
  accounts: SmartAccount[];
  signers: Signer[];
  createdAt: string | null;
  updatedAt: string | null;
  lastAccessed: string | null;
}

function formatDateTime(dateString: string | null) {
  if (!dateString) return null;
  const date = new Date(dateString);
  return date.toLocaleString([], {
    year: "numeric",
    month: "2-digit",
    day: "2-digit",
    hour: "2-digit",
    minute: "2-digit",
    second: "2-digit",
  });
}

function useGroupedSmartAccounts(user: User | null) {
  return useMemo(() => {
    // <-- ADDED: If user has no smartAccounts, return an empty array
    if (!user?.smartAccounts) return [];

    const grouped = user.smartAccounts.reduce<Record<string, SmartAccount[]>>(
      (acc, account) => {
        if (!acc[account.address]) acc[account.address] = [];
        acc[account.address].push(account);
        return acc;
      },
      {}
    );

    const groupedArray: GroupedAccountData[] = Object.entries(grouped).map(
      ([address, accounts]) => {
        const accountsWithDefaults = accounts.map((acc) => ({
          ...acc,
          value: acc.value ?? 0,
          nonce: acc.nonce ?? 0,
        }));

        const sortedAccounts = [...accountsWithDefaults].sort(
          (a, b) => b.value! - a.value!
        );

        const totalValue = sortedAccounts.reduce((sum, acc) => sum + acc.value!, 0);

        const getTime = (date?: Date) => (date ? new Date(date).getTime() : null);
        const validCreatedDates = accounts
          .map((a) => getTime(a.createdAt))
          .filter(Boolean) as number[];
        const validUpdatedDates = accounts
          .map((a) => getTime(a.updatedAt))
          .filter(Boolean) as number[];
        const validAccessedDates = accounts
          .map((a) => getTime(a.lastAccessed))
          .filter(Boolean) as number[];

        const createdAt =
          validCreatedDates.length > 0 ? new Date(Math.min(...validCreatedDates)) : null;
        const updatedAt =
          validUpdatedDates.length > 0 ? new Date(Math.max(...validUpdatedDates)) : null;
        const lastAccessed =
          validAccessedDates.length > 0 ? new Date(Math.max(...validAccessedDates)) : null;

        const signers = accounts[0].signers || [];

        return {
          address,
          totalValue,
          accounts: sortedAccounts,
          signers,
          createdAt: createdAt ? createdAt.toISOString() : null,
          updatedAt: updatedAt ? updatedAt.toISOString() : null,
          lastAccessed: lastAccessed ? lastAccessed.toISOString() : null,
        };
      }
    );

    groupedArray.sort((a, b) => b.totalValue - a.totalValue);
    return groupedArray;
  }, [user]);
}

// Helper functions for emoji logic
function getTimeDifferenceInDays(dateString: string | null) {
  if (!dateString) return null;
  const now = new Date().getTime();
  const date = new Date(dateString).getTime();
  const diffMs = now - date;
  return diffMs / (1000 * 60 * 60 * 24);
}

function getTimeDifferenceInMinutes(dateString: string | null) {
  if (!dateString) return null;
  const now = new Date().getTime();
  const date = new Date(dateString).getTime();
  const diffMs = now - date;
  return diffMs / (1000 * 60);
}

// Creation emojis
function getCreationEmoji(createdAt: string | null) {
  const days = getTimeDifferenceInDays(createdAt);
  if (days === null) return "";
  if (days < 7) return "🐥";
  if (days > 90) return "🤘";
  return "🧑";
}

// Last Access emojis
function getLastAccessedEmoji(lastAccessed: string | null) {
  const days = getTimeDifferenceInDays(lastAccessed);
  if (days === null) return "";
  if (days < 1) return "🍃";
  if (days >= 1 && days <= 7) return "🍂";
  return "👴";
}

// Updated emojis
function getUpdateEmoji(updatedAt: string | null) {
  const minutes = getTimeDifferenceInMinutes(updatedAt);
  if (minutes === null) return "";
  if (minutes < 5) return "✅";
  if (minutes >= 5 && minutes <= 1440) return "🔄"; // 1440 min = 1 day
  return "⌛";
}

function getExecutedOnEmoji(executedOn: string | null) {
  return getLastAccessedEmoji(executedOn);
}

// Emojis for function names
function getFunctionNameEmoji(functionName: string) {
  switch (functionName) {
    case "createPortfolio":
      return "🆕";
    case "depositPortfolio":
      return "💰";
    case "editPortfolio":
      return "✏️";
    case "withdrawPortfolio":
      return "💸";
    default:
      return "";
  }
}

function computeTotalValue(functionName: string, inputs: any[] = [], outputs: any[] = []): number {
  let total = 0;
  if (functionName === "withdrawPortfolio") {
    // sum outputs
    for (const out of outputs) {
      if (out.value) {
        total += out.value;
      }
    }
  } else {
    // create, deposit, edit → sum inputs
    for (const inp of inputs) {
      if (inp.value) {
        total += inp.value;
      }
    }
  }
  return total;
}

function getPortfolioEmoji(parentNftId?: number) {
  if (!parentNftId) return "";
  return portfolioEmojis[parentNftId] || "";
}

const SearchForm: React.FC<{
  searchType: string;
  searchValue: string;
  setSearchType: (type: string) => void;
  setSearchValue: (value: string) => void;
  onSearch: (e?: React.FormEvent) => void;
}> = ({ searchType, searchValue, setSearchType, setSearchValue, onSearch }) => {
  const searchTypes = [
    { value: "email", title: "Email" },
    { value: "taxId", title: "Tax ID" },
    { value: "smartAccount", title: "Smart Account" },
    { value: "address", title: "Signer" },
  ];

  return (
    <div>
      <div className="px-4 sm:px-0">
        <h3 className="text-lg font-medium leading-7 text-gray-900">User Search</h3>
        <p className="mt-1 max-w-2xl text-sm leading-6 text-gray-500">
          Search for user information.
        </p>
      </div>
      <div className="mt-5 border-t border-gray-100">
        <dl className="divide-y divide-gray-100">
          <div className="py-5">
            <fieldset>
              <legend className="text-sm font-medium text-gray-900">Search Type</legend>
              <p className="mt-1 text-sm text-gray-600">
                Select the type of information you have.
              </p>
              <div className="mt-4 space-y-4 sm:flex sm:items-center sm:space-x-10 sm:space-y-0">
                {searchTypes.map((type) => (
                  <div key={type.value} className="flex items-center">
                    <input
                      id={type.value}
                      name="search-type"
                      type="radio"
                      value={type.value}
                      checked={searchType === type.value}
                      onChange={(e) => setSearchType(e.target.value)}
                      className="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-600"
                    />
                    <label
                      htmlFor={type.value}
                      className="ml-3 block text-sm text-gray-900"
                    >
                      {type.title}
                    </label>
                  </div>
                ))}
              </div>
            </fieldset>
          </div>
          <div className="w-1/2 pt-5">
            <label htmlFor="searchValue" className="text-sm font-medium text-gray-900">
              Search value
            </label>
            <div className="mt-2">
              <input
                type="text"
                id="searchValue"
                className="block w-full p-2 sm:text-sm rounded-md border-0 py-1.5 text-gray-900 shadow-sm 
                           ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 
                           focus:ring-2 focus:ring-inset focus:ring-indigo-600"
                value={searchValue}
                onChange={(e) => setSearchValue(e.target.value)}
                onKeyDown={(e) => {
                  if (e.key === "Enter") {
                    onSearch();
                  }
                }}
              />
            </div>
          </div>
          <div className="px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
            <dt className="text-sm text-gray-900"></dt>
            <dd className="mt-1 text-sm text-gray-700 sm:col-span-2 sm:mt-0">
              <button
                className="px-4 py-2 bg-indigo-600 text-white text-sm rounded-md"
                onClick={() => onSearch()}
              >
                Search
              </button>
            </dd>
          </div>
        </dl>
      </div>
    </div>
  );
};

// <-- MODIFIED to handle missing user fields gracefully
const UserDetails: React.FC<{ user: User }> = ({ user }) => {
  // If there's absolutely no user ID or email, you can conditionally show a fallback message
  // or simply show minimal sections. The code below only shows the fields that exist.
  return (
    <div className="mt-6 border-t border-gray-100">
      <dl className="divide-y divide-gray-100">
        {user.id && <DetailRow label="User ID" value={user.id} />}

        {/* Only render email section if user.email is present */}
        {user.email && <DetailRow label="Email address" value={user.email} />}

        {/* Tax ID row only if user.taxId exists */}
        {user.taxId && (
          <DetailRow
            label="Tax ID"
            value={`${user.taxId} (${user.kycType || ""})`}
          />
        )}

        {/* KYC level row only if user.kycLevel is not undefined */}
        {user.kycLevel && (
          <DetailRow label="KYC Level" value={user.kycLevel.toString()} />
        )}

        {/* Birth date only if user.birthDate exists */}
        {user.birthDate && (
          <DetailRow
            label="Birth Date"
            value={new Date(user.birthDate).toLocaleDateString([], {
              year: "numeric",
              month: "2-digit",
              day: "2-digit",
            })}
          />
        )}

        {/* KYC Failure reason only if it exists */}
        {user.reasonForKycFailure && (
          <DetailRow label="KYC Failure Reason" value={user.reasonForKycFailure} />
        )}

        {/* Monerium info only if ID exists */}
        {user.moneriumId && (
          <DetailRow
            label="Monerium"
            value={
              <div className="space-y-1">
                <p>ID: {user.moneriumId}</p>
                <p>Status: {user.moneriumStatus || "N/A"}</p>
              </div>
            }
          />
        )}
      </dl>
    </div>
  );
};

const DetailRow: React.FC<{ label: string; value: React.ReactNode }> = ({
  label,
  value,
}) => (
  <div className="px-4 py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
    <dt className="text-sm font-medium text-gray-900">{label}</dt>
    <dd className="mt-1 text-sm text-gray-700 sm:col-span-2 sm:mt-0">
      {value}
    </dd>
  </div>
);

const SmartAccountsSection: React.FC<{
  groupedAccounts: GroupedAccountData[];
}> = ({ groupedAccounts }) => {
  const [expandedGroups, setExpandedGroups] = useState<Record<string, boolean>>(
    {}
  );

  // For sections
  const [showAccountsByChain, setShowAccountsByChain] = useState<
    Record<string, boolean>
  >({});
  const [showSwaps, setShowSwaps] = useState<Record<string, boolean>>({});
  const [showRampOrders, setShowRampOrders] = useState<Record<string, boolean>>(
    {}
  );
  const [showTransactions, setShowTransactions] = useState<
    Record<string, boolean>
  >({});
  const [showBaskets, setShowBaskets] = useState<Record<string, boolean>>({});

  // For basket allocations: key = address + basketIndex
  const [expandedAllocations, setExpandedAllocations] = useState<
    Record<string, boolean>
  >({});

  const [expandedAssets, setExpandedAssets] = useState<Record<string, boolean>>(
    {}
  );

  // For undeployed scenario categories
  const [showCategoryItems, setShowCategoryItems] = useState<
    Record<string, boolean>
  >({});

  const isOnlyOneUniqueAddress = groupedAccounts.length === 1;

  useEffect(() => {
    const initialShowAccountsByChain: Record<string, boolean> = {};
    const initialShowSwaps: Record<string, boolean> = {};
    const initialShowRampOrders: Record<string, boolean> = {};
    const initialShowTransactions: Record<string, boolean> = {};
    const initialShowBaskets: Record<string, boolean> = {};
    const initialShowCategoryItems: Record<string, boolean> = {};

    groupedAccounts.forEach((group) => {
      initialShowAccountsByChain[group.address] = false;
      initialShowSwaps[group.address] = false;
      initialShowRampOrders[group.address] = false;
      initialShowTransactions[group.address] = false;
      initialShowBaskets[group.address] = false;
      initialShowCategoryItems[group.address] = false;
    });
    setShowAccountsByChain(initialShowAccountsByChain);
    setShowSwaps(initialShowSwaps);
    setShowRampOrders(initialShowRampOrders);
    setShowTransactions(initialShowTransactions);
    setShowBaskets(initialShowBaskets);
    setShowCategoryItems(initialShowCategoryItems);
  }, [groupedAccounts]);

  const toggleGroup = (address: string) => {
    setExpandedGroups((prev) => ({
      ...prev,
      [address]: !prev[address],
    }));
  };

  const toggleAccountsByChain = (address: string) => {
    setShowAccountsByChain((prev) => ({
      ...prev,
      [address]: !prev[address],
    }));
  };

  const toggleAssets = (accountKey: string) => {
    setExpandedAssets((prev) => ({
      ...prev,
      [accountKey]: !prev[accountKey],
    }));
  };

  const toggleSwaps = (address: string) => {
    setShowSwaps((prev) => ({
      ...prev,
      [address]: !prev[address],
    }));
  };

  const toggleRampOrders = (address: string) => {
    setShowRampOrders((prev) => ({
      ...prev,
      [address]: !prev[address],
    }));
  };

  const toggleTransactions = (address: string) => {
    setShowTransactions((prev) => ({
      ...prev,
      [address]: !prev[address],
    }));
  };

  const toggleBaskets = (address: string) => {
    setShowBaskets((prev) => ({
      ...prev,
      [address]: !prev[address],
    }));
  };

  const toggleAllocation = (basketKey: string) => {
    setExpandedAllocations((prev) => ({
      ...prev,
      [basketKey]: !prev[basketKey],
    }));
  };

  const toggleCategoryItemsFunc = (address: string) => {
    setShowCategoryItems((prev) => ({
      ...prev,
      [address]: !prev[address],
    }));
  };

  const formatSwapStatus = (status?: string) => {
    if (!status) return "N/A";
    const upperStatus = status.toUpperCase();
    switch (upperStatus) {
      case "SUCCESS":
        return <span className="text-green-600">✅ Success</span>;
      case "FAILED":
        return <span className="text-red-600">❌ Failed</span>;
      case "PENDING":
        return <span className="text-yellow-600">⏳ Pending</span>;
      case "UNDEFINED":
        return <span className="text-red-600">❓ Undefined</span>;
      default:
        return status;
    }
  };

  return (
    <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
      <dt className="text-sm font-medium text-gray-900">Smart Accounts</dt>
      <dd className="mt-2 text-sm text-gray-900 sm:col-span-2">
        {groupedAccounts.map((group) => {
          const isExpanded =
            isOnlyOneUniqueAddress || expandedGroups[group.address] || false;
          const filteredAccounts = group.accounts.filter(
            (acc) => (acc.value && acc.value > 0) || (acc.nonce && acc.nonce > 0)
          );

          // Compute total nonce across all accounts in the group
          const totalNonce = group.accounts.reduce(
            (sum, acc) => sum + (acc.nonce || 0),
            0
          );

          // If totalValue=0 AND totalNonce=0 => undeployed wallet
          const isUndeployedWallet =
            group.totalValue === 0 && totalNonce === 0;

          const uniqueChainIds = Array.from(
            new Set(group.accounts.map((acc) => acc.chainId))
          );
          const allTransactions = group.accounts.flatMap((acc) =>
            (acc.transactions || []).map((tx) => ({
              ...tx,
              chainId: acc.chainId,
            }))
          );
          const allSwaps = group.accounts.flatMap((acc) => acc.swaps || []);
          const allRampOrders = group.accounts.flatMap(
            (acc) => acc.rampOrders || []
          );
          const allBaskets = group.accounts.flatMap((acc) => acc.baskets || []);

          const accountsByChainExpanded = showAccountsByChain[group.address] ?? false;
          const swapsExpanded = showSwaps[group.address] ?? false;
          const rampOrdersExpanded = showRampOrders[group.address] ?? false;
          const transactionsExpanded = showTransactions[group.address] ?? false;
          const basketsExpanded = showBaskets[group.address] ?? false;

          // Gather all allocations that have category
          const categoryItems: BasketAllocation[] = [];
          for (const basket of allBaskets) {
            for (const alloc of basket.allocation) {
              if (alloc.category && alloc.category.length > 0) {
                categoryItems.push(alloc);
              }
            }
          }

          const hasCategoryItems = categoryItems.length > 0;

          // Determine if there are any sections with data
          const hasSectionsWithData =
            filteredAccounts.length > 0 ||
            allSwaps.length > 0 ||
            allRampOrders.length > 0 ||
            allTransactions.length > 0 ||
            allBaskets.length > 0 ||
            hasCategoryItems;

          return (
            <div key={group.address} className="mb-5">
              <div className="flex items-center justify-between border-b border-gray-200 pb-2">
                <div className="flex flex-col">
                  <div className="flex items-center gap-3">
                    <h4 className="text-sm font-medium truncate">
                      <a
                        href={`https://debank.com/profile/${group.address}`}
                        target="_blank"
                        rel="noopener noreferrer"
                        className="text-indigo-600 underline"
                      >
                        {group.address}
                      </a>{" "}
                      {isUndeployedWallet
                        ? "($0.00 undeployed wallet)"
                        : `(Total: $${group.totalValue.toFixed(2)})`}
                    </h4>
                    {!isUndeployedWallet && (
                      <div className="flex items-center space-x-2">
                        {uniqueChainIds.map((chainId) => (
                          <ChainLogo key={chainId} chainId={chainId} />
                        ))}
                      </div>
                    )}
                  </div>
                  <div className="text-xs text-gray-500 mt-2 flex flex-col space-y-1">
                    {group.createdAt && (
                      <div>
                        Created: {formatDateTime(group.createdAt)}{" "}
                        {getCreationEmoji(group.createdAt)}
                      </div>
                    )}
                    {group.updatedAt && (
                      <div>
                        Updated: {formatDateTime(group.updatedAt)}{" "}
                        {getUpdateEmoji(group.updatedAt)}
                      </div>
                    )}
                    {group.lastAccessed && (
                      <div>
                        Last accessed: {formatDateTime(group.lastAccessed)}{" "}
                        {getLastAccessedEmoji(group.lastAccessed)}
                      </div>
                    )}
                    {group.signers && group.signers.length > 0 && (
                      <div>
                        {group.signers.map((signer, i) => (
                          <div key={i}>
                            Signer:{" "}
                            <a
                              href={`https://debank.com/profile/${signer?.address}`}
                              target="_blank"
                              rel="noopener noreferrer"
                              className="text-indigo-600 underline"
                            >
                              {signer?.address}
                            </a>{" "}
                            (Type: {signer?.type})
                          </div>
                        ))}
                      </div>
                    )}
                  </div>
                </div>
                {!isOnlyOneUniqueAddress && (
                  <button
                    onClick={() => toggleGroup(group.address)}
                    className="text-xs text-gray-600 hover:text-gray-800 focus:outline-none"
                    aria-label="Toggle group visibility"
                  >
                    {isExpanded ? "▼" : "▶"}
                  </button>
                )}
              </div>

              {/* Show 'No additional data' if wallet is undeployed and no other sections have data */}
              {isExpanded && isUndeployedWallet && !hasSectionsWithData && (
                <div className="mt-2 text-xs text-gray-500">
                  No additional data available.
                </div>
              )}

              {/* Show category items if wallet is undeployed but there are category items */}
              {isExpanded && isUndeployedWallet && hasCategoryItems && (
                <div className="mt-2 text-xs text-gray-500">
                  <div className="mt-3">
                    <div className="flex items-center justify-between">
                      <p className="text-xs font-medium text-gray-900 mb-2">
                        Items with Categories
                      </p>
                      <button
                        onClick={() => toggleCategoryItemsFunc(group.address)}
                        className="text-xs text-gray-600 hover:text-gray-800 focus:outline-none"
                      >
                        {showCategoryItems[group.address]
                          ? "Hide Category Items ▼"
                          : "Show Category Items ▶"}
                      </button>
                    </div>
                    {showCategoryItems[group.address] && (
                      <ul className="divide-y divide-gray-200 border border-gray-200 rounded-md">
                        {categoryItems.map((alloc, i) => (
                          <li
                            key={i}
                            className="p-3 text-sm text-gray-700 bg-gray-50 rounded-md mt-1"
                          >
                            <div>
                              <strong>{alloc.name}</strong> ({alloc.symbol})
                            </div>
                            <div>Categories: {alloc.category?.join(", ")}</div>
                            <div>Amount: {alloc.amount}</div>
                            <div>Price: ${alloc.price.toFixed(2)}</div>
                            <div>
                              Fraction: {(alloc.fraction * 100).toFixed(2)}%
                            </div>
                          </li>
                        ))}
                      </ul>
                    )}
                  </div>
                </div>
              )}

              {/* Always show the sections if there is data */}
              {isExpanded && hasSectionsWithData && (
                <>
                  {filteredAccounts.length > 0 && (
                    <div className="mt-5">
                      <div className="flex items-center justify-between">
                        <p className="text-xs font-medium text-gray-900 mb-2">
                          Smart Accounts by Chain
                        </p>
                        <button
                          onClick={() => toggleAccountsByChain(group.address)}
                          className="text-xs text-gray-600 hover:text-gray-800 focus:outline-none"
                        >
                          {showAccountsByChain[group.address]
                            ? "Hide Smart Accounts by Chain ▼"
                            : "Show Smart Accounts by Chain ▶"}
                        </button>
                      </div>
                      {showAccountsByChain[group.address] && (
                        <ul className="divide-y divide-gray-100 border border-gray-200 rounded-md mt-3">
                          {filteredAccounts.map((account, index) => {
                            const accountKey = `${group.address}-${index}`;
                            const sortedAssets = account.cachedAssetBalances
                              ? [...account.cachedAssetBalances].sort((a, b) => {
                                  const usdA = a.value?.usd ?? 0;
                                  const usdB = b.value?.usd ?? 0;
                                  return usdB - usdA;
                                })
                              : [];

                            return (
                              <li
                                key={index}
                                className="p-3 text-sm text-gray-700"
                              >
                                <div className="flex items-center justify-between w-full">
                                  <div className="flex items-center gap-3 flex-wrap">
                                    {blockExplorerUrls[account.chainId] ? (
                                      <a
                                        href={`${blockExplorerUrls[account.chainId]}/address/${account.address}`}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        className="text-indigo-600 underline truncate"
                                      >
                                        {account.address}
                                      </a>
                                    ) : (
                                      <span className="truncate">
                                        {account.address}
                                      </span>
                                    )}
                                    <span>
                                      $
                                      {account.value
                                        ? account.value.toFixed(2)
                                        : "0.00"}
                                    </span>
                                    <span>Nonce: {account.nonce ?? "0"}</span>
                                    <ChainLogo chainId={account.chainId} />
                                  </div>
                                  {sortedAssets.length > 0 && (
                                    <button
                                      onClick={() => toggleAssets(accountKey)}
                                      className="ml-4 text-xs text-gray-600 hover:text-gray-800 focus:outline-none"
                                    >
                                      {expandedAssets[accountKey]
                                        ? "Hide Assets ▼"
                                        : "Show Assets ▶"}
                                    </button>
                                  )}
                                </div>

                                {expandedAssets[accountKey] &&
                                  sortedAssets.length > 0 && (
                                    <ul className="mt-3 ml-4 border-l border-gray-200 pl-3 space-y-2">
                                      {sortedAssets.map((asset, i) => {
                                        const explorerUrl =
                                          blockExplorerUrls[account.chainId];
                                        return (
                                          <li
                                            key={i}
                                            className="text-sm text-gray-700"
                                          >
                                            <div className="flex justify-between gap-3 flex-wrap">
                                              <div className="truncate">
                                                <strong>{asset.symbol}</strong>{" "}
                                                ({asset.groupAssetId}){" "}
                                                {asset.address && explorerUrl ? (
                                                  <a
                                                    href={`${explorerUrl}/token/${asset.address}?a=${account.address}`}
                                                    target="_blank"
                                                    rel="noopener noreferrer"
                                                    className="ml-2 text-indigo-600 underline"
                                                  >
                                                    {asset.address}
                                                  </a>
                                                ) : (
                                                  <span className="ml-2 text-gray-500">
                                                    {asset.address}
                                                  </span>
                                                )}
                                              </div>
                                              <div className="flex flex-col text-right">
                                                <span>
                                                  Balance: {asset.formattedBalance}
                                                </span>
                                                {asset.value?.usd !==
                                                  undefined && (
                                                  <span>
                                                    USD: $
                                                    {asset.value.usd.toFixed(4)}
                                                  </span>
                                                )}
                                              </div>
                                            </div>
                                          </li>
                                        );
                                      })}
                                    </ul>
                                  )}
                              </li>
                            );
                          })}
                        </ul>
                      )}
                    </div>
                  )}

                  {allSwaps.length > 0 && (
                    <div className="mt-5">
                      <div className="flex items-center justify-between">
                        <p className="text-xs font-medium text-gray-900 mb-2">
                          Swaps
                        </p>
                        <button
                          onClick={() => toggleSwaps(group.address)}
                          className="text-xs text-gray-600 hover:text-gray-800 focus:outline-none"
                        >
                          {showSwaps[group.address]
                            ? "Hide Swaps ▼"
                            : "Show Swaps ▶"}
                        </button>
                      </div>
                      {swapsExpanded && (
                        <ul className="divide-y divide-gray-100 border border-gray-200 rounded-md">
                          {allSwaps.map((swap, idx) => {
                            const isSameChain =
                              swap.fromToken?.chainId === swap.toToken?.chainId;

                            const fromExplorer =
                              swap.fromToken?.chainId !== undefined
                                ? blockExplorerUrls[swap.fromToken.chainId]
                                : undefined;

                            const toExplorer =
                              swap.toToken?.chainId !== undefined
                                ? blockExplorerUrls[swap.toToken.chainId]
                                : undefined;

                            return (
                              <li key={idx} className="p-3 text-sm text-gray-700">
                                <div className="flex flex-col space-y-1">
                                  <div className="flex flex-col sm:flex-row sm:items-center sm:space-x-2">
                                    <strong>From:</strong>
                                    {swap.fromToken && fromExplorer && swap.fromToken.address ? (
                                      <a
                                        href={`${fromExplorer}/token/${swap.fromToken.address}`}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        className="text-indigo-600 underline flex items-center gap-1"
                                      >
                                        {swap.fromToken.symbol}
                                        {swap.fromToken.chainId && (
                                          <ChainLogo
                                            chainId={swap.fromToken.chainId}
                                          />
                                        )}
                                      </a>
                                    ) : (
                                      <span>
                                        {swap.fromToken?.symbol}{" "}
                                        {swap.fromToken?.chainId && (
                                          <ChainLogo
                                            chainId={swap.fromToken.chainId}
                                          />
                                        )}
                                      </span>
                                    )}

                                    <span className="sm:ml-3 sm:mr-3">→</span>

                                    <strong>To:</strong>
                                    {swap.toToken && toExplorer && swap.toToken.address ? (
                                      <a
                                        href={`${toExplorer}/token/${swap.toToken.address}`}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        className="text-indigo-600 underline flex items-center gap-1"
                                      >
                                        {swap.toToken.symbol}
                                        {swap.toToken.chainId && (
                                          <ChainLogo
                                            chainId={swap.toToken.chainId}
                                          />
                                        )}
                                      </a>
                                    ) : (
                                      <span>
                                        {swap.toToken?.symbol}{" "}
                                        {swap.toToken?.chainId && (
                                          <ChainLogo
                                            chainId={swap.toToken.chainId}
                                          />
                                        )}
                                      </span>
                                    )}
                                  </div>
                                  <div>
                                    <strong>Status:</strong>{" "}
                                    {formatSwapStatus(swap.swapStatus)}
                                  </div>
                                  {swap.swapError && (
                                    <div className="text-red-600">
                                      <strong>Error:</strong> {swap.swapError}
                                    </div>
                                  )}

                                  {isSameChain ? (
                                    <>
                                      {swap.receiving?.txHash &&
                                      swap.receiving.chainId &&
                                      blockExplorerUrls[swap.receiving.chainId] ? (
                                        <div>
                                          <strong>Tx Hash:</strong>{" "}
                                          <a
                                            href={`${blockExplorerUrls[swap.receiving.chainId]}/tx/${swap.receiving.txHash}`}
                                            target="_blank"
                                            rel="noopener noreferrer"
                                            className="text-indigo-600 underline"
                                          >
                                            {swap.receiving.txHash}
                                          </a>
                                        </div>
                                      ) : swap.sending?.txHash &&
                                        swap.sending.chainId &&
                                        blockExplorerUrls[swap.sending.chainId] ? (
                                        <div>
                                          <strong>Tx Hash:</strong>{" "}
                                          <a
                                            href={`${blockExplorerUrls[swap.sending.chainId]}/tx/${swap.sending.txHash}`}
                                            target="_blank"
                                            rel="noopener noreferrer"
                                            className="text-indigo-600 underline"
                                          >
                                            {swap.sending.txHash}
                                          </a>
                                        </div>
                                      ) : (
                                        <>
                                          {swap.receiving?.txHash && (
                                            <div>
                                              <strong>Tx Hash:</strong>{" "}
                                              {swap.receiving.txHash}
                                            </div>
                                          )}
                                          {!swap.receiving?.txHash &&
                                            swap.sending?.txHash && (
                                              <div>
                                                <strong>Tx Hash:</strong>{" "}
                                                {swap.sending.txHash}
                                              </div>
                                            )}
                                        </>
                                      )}
                                    </>
                                  ) : (
                                    <>
                                      {swap.sending?.txHash &&
                                        swap.sending.chainId &&
                                        blockExplorerUrls[swap.sending.chainId] && (
                                          <div>
                                            <strong>Tx Hash (Sending):</strong>{" "}
                                            <a
                                              href={`${blockExplorerUrls[swap.sending.chainId]}/tx/${swap.sending.txHash}`}
                                              target="_blank"
                                              rel="noopener noreferrer"
                                              className="text-indigo-600 underline"
                                            >
                                              {swap.sending.txHash}
                                            </a>
                                          </div>
                                        )}
                                      {swap.receiving?.txHash &&
                                        swap.receiving.chainId &&
                                        blockExplorerUrls[swap.receiving.chainId] && (
                                          <div>
                                            <strong>Tx Hash (Receiving):</strong>{" "}
                                            <a
                                              href={`${blockExplorerUrls[swap.receiving.chainId]}/tx/${swap.receiving.txHash}`}
                                              target="_blank"
                                              rel="noopener noreferrer"
                                              className="text-indigo-600 underline"
                                            >
                                              {swap.receiving.txHash}
                                            </a>
                                          </div>
                                        )}
                                    </>
                                  )}
                                </div>
                              </li>
                            );
                          })}
                        </ul>
                      )}
                    </div>
                  )}

                  {allRampOrders.length > 0 && (
                    <div className="mt-5">
                      <div className="flex items-center justify-between">
                        <p className="text-xs font-medium text-gray-900 mb-2">
                          Ramp Orders
                        </p>
                        <button
                          onClick={() => toggleRampOrders(group.address)}
                          className="text-xs text-gray-600 hover:text-gray-800 focus:outline-none"
                        >
                          {showRampOrders[group.address]
                            ? "Hide Ramp Orders ▼"
                            : "Show Ramp Orders ▶"}
                        </button>
                      </div>
                      {rampOrdersExpanded && (
                        <ul className="divide-y divide-gray-200 border border-gray-200 rounded-md">
                          {allRampOrders.map((order, idx) => {
                            let currencyFlag = "💲";
                            if (order.fiat === "BRL") {
                              currencyFlag = "🇧🇷";
                            } else if (order.fiat === "EUR") {
                              currencyFlag = "🇪🇺";
                            }

                            let statusEmoji = "ℹ️";
                            switch (order.status) {
                              case "mintSuccess":
                                statusEmoji = "🌱";
                                break;
                              case "transferSuccess":
                                statusEmoji = "🚀";
                                break;
                              case "paymentPending":
                              case "pendingPayment":
                                statusEmoji = "⏳";
                                break;
                              case "burnSuccess":
                                statusEmoji = "🔥";
                                break;
                              case "burnQueued":
                                statusEmoji = "⌛";
                                break;
                              default:
                                statusEmoji = "ℹ️";
                            }

                            return (
                              <li key={idx} className="p-3">
                                <div className="flex flex-col space-y-1 bg-gray-50 p-3 rounded-md text-xs text-gray-800">
                                  <div className="text-gray-700 font-semibold flex items-center">
                                    <span className="mr-2">
                                      Ramp Order #{idx + 1}
                                    </span>
                                    <span>•</span>
                                    <span className="ml-2">
                                      {statusEmoji} {order.status}
                                    </span>
                                  </div>
                                  <div className="text-gray-700">
                                    <strong>Fiat:</strong> {currencyFlag}{" "}
                                    {order.fiat}
                                  </div>
                                  <div className="text-gray-700">
                                    <strong>Fiat Amount:</strong>{" "}
                                    {order.fiatAmount.toFixed(2)}
                                  </div>
                                  <div className="text-gray-700">
                                    <strong>Asset Amount:</strong>{" "}
                                    {order.assetAmount.toFixed(2)}
                                  </div>
                                  <div className="text-gray-700">
                                    <strong>Created At:</strong>{" "}
                                    {formatDateTime(order.createdAt)}
                                  </div>
                                  <div className="text-gray-700">
                                    <strong>Updated At:</strong>{" "}
                                    {formatDateTime(order.updatedAt)}
                                  </div>
                                  {order.mintStatus && (
                                    <div className="text-gray-700">
                                      <strong>Mint Status:</strong>{" "}
                                      {order.mintStatus}
                                    </div>
                                  )}
                                </div>
                              </li>
                            );
                          })}
                        </ul>
                      )}
                    </div>
                  )}

                  {allTransactions.length > 0 && (
                    <div className="mt-5">
                      <div className="flex items-center justify-between">
                        <p className="text-xs font-medium text-gray-900 mb-2">
                          DeFiBasket Transactions
                        </p>
                        <button
                          onClick={() => toggleTransactions(group.address)}
                          className="text-xs text-gray-600 hover:text-gray-800 focus:outline-none"
                        >
                          {transactionsExpanded
                            ? "Hide DeFiBasket Transactions ▼"
                            : "Show DeFiBasket Transactions ▶"}
                        </button>
                      </div>
                      {transactionsExpanded && (
                        <ul className="divide-y divide-gray-200 border border-gray-200 rounded-md">
                          {allTransactions.map((tx, idx) => {
                            const portfolioName =
                              tx.parentNftId && portfolioNames[tx.parentNftId]
                                ? portfolioNames[tx.parentNftId]
                                : tx.parentNftId
                                ? `NFT ID: ${tx.parentNftId}`
                                : "N/A";

                            const totalValue = computeTotalValue(
                              tx.functionName,
                              tx.inputs,
                              tx.outputs
                            );
                            const totalValueString = totalValue.toLocaleString(
                              undefined,
                              {
                                minimumFractionDigits: 2,
                                maximumFractionDigits: 2,
                              }
                            );

                            const executedOnEmoji = getExecutedOnEmoji(tx.executedOn);
                            const functionNameEmoji = getFunctionNameEmoji(tx.functionName);
                            const portfolioEmoji = getPortfolioEmoji(tx.parentNftId);

                            const chainId = (tx as any).chainId;
                            const explorerUrl = chainId
                              ? blockExplorerUrls[chainId]
                              : null;

                            return (
                              <li key={idx} className="p-3">
                                <div className="flex flex-col space-y-1 bg-gray-50 p-3 rounded-md text-xs text-gray-800">
                                  <div className="text-gray-700 font-semibold flex items-center">
                                    <span className="mr-2">
                                      DeFiBasket Tx #{idx + 1}
                                    </span>
                                  </div>
                                  <div className="text-gray-700">
                                    <strong>Function Name:</strong>{" "}
                                    {tx.functionName} {functionNameEmoji}
                                  </div>
                                  <div className="text-gray-700">
                                    <strong>Transaction Hash:</strong>{" "}
                                    {tx.transactionHash && explorerUrl ? (
                                      <a
                                        href={`${explorerUrl}/tx/${tx.transactionHash}`}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        className="text-indigo-600 underline"
                                      >
                                        {tx.transactionHash}
                                      </a>
                                    ) : (
                                      <span>{tx.transactionHash || "N/A"}</span>
                                    )}
                                  </div>
                                  <div className="text-gray-700">
                                    <strong>Portfolio:</strong>{" "}
                                    {portfolioName} {portfolioEmoji}
                                  </div>
                                  {tx.executedOn && (
                                    <div className="text-gray-700">
                                      <strong>Executed On:</strong>{" "}
                                      {formatDateTime(tx.executedOn)}{" "}
                                      {executedOnEmoji}
                                    </div>
                                  )}
                                  <div className="text-gray-700">
                                    <strong>Total Value:</strong> $
                                    {totalValueString}
                                  </div>
                                </div>
                              </li>
                            );
                          })}
                        </ul>
                      )}
                    </div>
                  )}

                  {allBaskets.length > 0 && (
                    <div className="mt-5">
                      <div className="flex items-center justify-between">
                        <p className="text-xs font-medium text-gray-900 mb-2">
                          Baskets
                        </p>
                        <button
                          onClick={() => toggleBaskets(group.address)}
                          className="text-xs text-gray-600 hover:text-gray-800 focus:outline-none"
                        >
                          {basketsExpanded ? "Hide Baskets ▼" : "Show Baskets ▶"}
                        </button>
                      </div>
                      {basketsExpanded && (
                        <ul className="divide-y divide-gray-200 border border-gray-200 rounded-md">
                          {allBaskets.map((b, idx) => {
                            const basketPortfolioName =
                              b.parentNftId && portfolioNames[b.parentNftId]
                                ? portfolioNames[b.parentNftId]
                                : b.parentNftId
                                ? `NFT ID: ${b.parentNftId}`
                                : "N/A";

                            const basketPortfolioEmoji = getPortfolioEmoji(
                              b.parentNftId
                            );
                            const basketKey = `${group.address}-basket-${idx}`;
                            const isAllocationShown =
                              expandedAllocations[basketKey] || false;

                            return (
                              <li key={idx} className="p-3">
                                <div className="flex flex-col space-y-2 bg-gray-50 p-3 rounded-md text-xs text-gray-800">
                                  <div className="text-gray-700 font-semibold flex items-center">
                                    <span className="mr-2">
                                      Basket #{idx + 1}
                                    </span>
                                  </div>
                                  <div className="text-gray-700">
                                    <strong>nftId:</strong> {b.nftId}
                                  </div>
                                  {b.parentNftId && (
                                    <div className="text-gray-700">
                                      <strong>Parent NFT:</strong>{" "}
                                      {basketPortfolioName} {basketPortfolioEmoji}
                                    </div>
                                  )}
                                  <div className="text-gray-700">
                                    <strong>Name:</strong> {b.name}
                                  </div>
                                  <div className="text-gray-700">
                                    <strong>APY:</strong> {b.apy}%
                                  </div>
                                  <div className="text-gray-700">
                                    <strong>Created On:</strong>{" "}
                                    {formatDateTime(b.createdOn)}
                                  </div>
                                  <div className="text-gray-700">
                                    <strong>Value:</strong>{" "}
                                    ${b.value.toFixed(2)}
                                  </div>
                                  <div className="text-gray-700">
                                    <strong>PNL:</strong> {b.pnl.toFixed(2)}%
                                  </div>
                                  <div className="text-gray-700">
                                    <strong>Gross PNL:</strong>{" "}
                                    {b.grossPnl.toFixed(2)}%
                                  </div>
                                  <div>
                                    <button
                                      onClick={() => toggleAllocation(basketKey)}
                                      className="text-xs text-gray-600 hover:text-gray-800 focus:outline-none"
                                    >
                                      {isAllocationShown
                                        ? "Hide Allocation ▼"
                                        : "Show Allocation ▶"}
                                    </button>
                                  </div>
                                  {isAllocationShown && (
                                    <ul className="mt-3 ml-4 border-l border-gray-200 pl-3 space-y-2">
                                      {b.allocation.map((alloc, i) => (
                                        <li key={i} className="text-sm text-gray-700">
                                          <div className="flex flex-col space-y-1 bg-white p-2 rounded-md">
                                            <div>
                                              <strong>{alloc.name}</strong> ({alloc.symbol})
                                            </div>
                                            <div>Amount: {alloc.amount}</div>
                                            <div>
                                              Fraction:{" "}
                                              {(alloc.fraction * 100).toFixed(2)}
                                              %
                                            </div>
                                            <div>
                                              Price: ${alloc.price.toFixed(2)}
                                            </div>
                                            {alloc.logos &&
                                              alloc.logos.length > 0 && (
                                                <div className="flex items-center space-x-2 mt-1">
                                                  {alloc.logos.map(
                                                    (logo, li) => (
                                                      <img
                                                        key={li}
                                                        src={logo.logoUri}
                                                        alt={logo.symbol}
                                                        className="h-4 w-4 inline-block"
                                                        onError={(e) => {
                                                          e.currentTarget.src =
                                                            "/img/chains/default.png";
                                                        }}
                                                      />
                                                    )
                                                  )}
                                                </div>
                                              )}
                                          </div>
                                        </li>
                                      ))}
                                    </ul>
                                  )}
                                </div>
                              </li>
                            );
                          })}
                        </ul>
                      )}
                    </div>
                  )}
                </>
              )}
            </div>
          );
        })}
      </dd>
    </div>
  );
};

// <-- MODIFIED to gracefully handle when addresses are missing/null
//     We default to an empty array and only render if there is data.
const AddressesSection: React.FC<{
  addresses?: { address: string; type: number }[];
}> = ({ addresses = [] }) => {
  if (!addresses.length) return null; // If empty array, render nothing

  return (
    <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
      <dt className="text-sm font-medium text-gray-900">Addresses</dt>
      <dd className="mt-2 text-sm text-gray-900 sm:col-span-2">
        <ul className="divide-y divide-gray-100 rounded-md border border-gray-200">
          {addresses.map((addr, index) => (
            <li
              key={index}
              className="flex items-center justify-between py-3 pl-4 pr-5 text-sm"
            >
              <div className="flex w-0 flex-1 items-center gap-2">
                <span className="truncate">{addr.address}</span>
                <span className="text-gray-400">Type: {addr.type}</span>
              </div>
            </li>
          ))}
        </ul>
      </dd>
    </div>
  );
};

const ChainLogo: React.FC<{ chainId: number }> = ({ chainId }) => (
  <img
    src={`/img/chains/eip155_${chainId}.png`}
    alt={`Chain ${chainId} logo`}
    className="h-4 w-4 inline-block"
    onError={(e) => {
      e.currentTarget.src = "/img/chains/default.png";
    }}
  />
);

const UserSearch: React.FC = () => {
  const [searchType, setSearchType] = useState<string>("email");
  const [searchValue, setSearchValue] = useState<string>("");
  const [user, setUser] = useState<User | null>(null);
  const [error, setError] = useState<string | null>(null);

  const groupedAccounts = useGroupedSmartAccounts(user);

  const handleSearch = async (e?: React.FormEvent) => {
    if (e) e.preventDefault();
    setError(null);
    setUser(null);

    try {
      let endpoint = "";
      switch (searchType) {
        case "email":
          endpoint = `/api/user/email/${searchValue}`;
          break;
        case "taxId":
          endpoint = `/api/user/taxId/${searchValue}`;
          break;
        case "smartAccount":
          endpoint = `/api/user/smartAccount/${searchValue}`;
          break;
        case "address":
          endpoint = `/api/user/address/${searchValue}`;
          break;
        default:
          throw new Error("Invalid search type");
      }

      const response = await api.get(endpoint);
      const userData: User = response?.data;
      setUser(userData);
    } catch (err: any) {
      setError(`User not found or server error: ${err}`);
    }
  };

  return (
    <div className="space-y-6">
      <SearchForm
        searchType={searchType}
        searchValue={searchValue}
        setSearchType={setSearchType}
        setSearchValue={setSearchValue}
        onSearch={handleSearch}
      />

      {error && <p className="text-sm text-red-600">{error}</p>}

      {user && (
        <div className="border-t border-gray-100 pt-6">
          {/* If user has no fields besides smartAccounts, 
              this section won't crash and will show only what's available */}
          <UserDetails user={user} /> {/* <-- Safe with optional chaining */}
          <SmartAccountsSection groupedAccounts={groupedAccounts} />
          <AddressesSection addresses={user.addresses} />

          {/* Emoji rules documentation */}
          <div className="text-xs mt-6 text-gray-500">
            <p><strong>Emoji rules:</strong></p>
            <p><strong>Creation Date:</strong> &lt;1 week: 🐥, &gt;3 months: 🤘, else: 🧑</p>
            <p><strong>Last Accessed:</strong> &lt;1 day: 🍃, 1 day-1 week: 🍂, &gt;1 week: 👴</p>
            <p><strong>Updated:</strong> &lt;5 mins: ✅, 5 mins-1 day: 🔄, &gt;1 day: ⌛</p>
          </div>
        </div>
      )}
    </div>
  );
};

export default UserSearch;
